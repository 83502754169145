import "./navbar.scss";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
} from "mdb-react-ui-kit";
import React, { useState } from "react";
import Brand from "../brand/brand";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [openBasic, setOpenBasic] = useState(false);

  return (
    <div>
      <MDBNavbar expand="lg" light bgColor="light">
        <MDBContainer fluid>
          <MDBNavbarBrand onClick={() => setOpenBasic(false)}>
            <Link to="/">
              <Brand />
            </Link>
          </MDBNavbarBrand>

          <MDBNavbarToggler
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setOpenBasic(!openBasic)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>

          <MDBCollapse
            className="nav-collapse"
            navbar
            open={openBasic}
            onClick={() => setOpenBasic(false)}
          >
            <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
              <MDBNavbarItem>
                <Link to="/">
                  <MDBNavbarLink
                    className="navbar-link"
                    active
                    aria-current="page"
                  >
                    Home
                  </MDBNavbarLink>
                </Link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <Link to="/sip-calculator">
                  <MDBNavbarLink
                    className="navbar-link"
                    active
                    aria-current="page"
                  >
                    Sip Calculator
                  </MDBNavbarLink>
                </Link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <Link to="/lumpsum-calculator">
                  <MDBNavbarLink
                    className="navbar-link"
                    active
                    aria-current="page"
                  >
                    Lumpsum Calculator
                  </MDBNavbarLink>
                </Link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <Link to="/fd-calculator">
                  <MDBNavbarLink
                    className="navbar-link"
                    active
                    aria-current="page"
                  >
                    FD Calculator
                  </MDBNavbarLink>
                </Link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <Link to="/mutualfunds/disclaimer">
                  <MDBNavbarLink
                    className="navbar-link"
                    active
                    aria-current="page"
                  >
                    Disclaimer
                  </MDBNavbarLink>
                </Link>
              </MDBNavbarItem>
              {/* <MDBNavbarItem>
                <MDBNavbarLink href="#">Link</MDBNavbarLink>
              </MDBNavbarItem> */}
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </div>
  );
};

export default NavBar;
