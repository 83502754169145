import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="contacts">
        <div className="email">
          <p className="contact-label">E-mail: </p>
          <a href="mailto:info@realmalpha.com" className="contact-link">
            info@realmalpha.com
          </a>
        </div>
        <div className="phone">
          <p className="contact-label">Phone: </p>
          <a href="tel:+918129036020" className="contact-link">
            +918129036020
          </a>
        </div>
      </div>
      <div className="arn">
        <div className="arn-no">
          <p className="arn-label">ARN No. :</p>
          <p className="arn-value">244744</p>
        </div>
        <div className="arn-velidity">
          <p className="arn-label">Valid thru :</p>
          <p className="arn-value">04-04-2025</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
