import React from "react";
import logo from "./logo.svg";
import "./App.scss";
import NavBar from "./components/navbar/navbar";
import Home from "./components/home/home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SipCalc from "./components/sip-calc/sipcalc";
import LumpCalc from "./components/lump-calc/lumpcalc";
import FdCalc from "./components/fd-calc/fdcalc";
import io from "socket.io-client";
import Disclaimer from "./components/disclaimer/disclaimer";
import Footer from "./features/footer/footer";

export const socket = io(process.env.REACT_APP_BASE_URL!, {
  path: "/ws/",
});
// export const socket = io("https://realmalpha.com/", {
//   path: "/ws/",
// });

console.log();

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <header className="App-header">
          <NavBar />
        </header>

        <div className="app-body">
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/sip-calculator" element={<SipCalc />}></Route>
            <Route path="/lumpsum-calculator" element={<LumpCalc />}></Route>
            <Route path="/fd-calculator" element={<FdCalc />}></Route>
            <Route
              path="/mutualfunds/disclaimer"
              element={<Disclaimer />}
            ></Route>
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
