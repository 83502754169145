import React from "react";
import "./brand.scss";
// import Logo from "../..data/realm alpha logo without text.png";
// import Logo from "../../data/realm alpha logo without text.png";
import Logo from "../../data/realm-logo-no-text.svg";

const Brand = () => {
  return (
    <div className="brand-container">
      <img src={Logo} alt="" className="brand-logo" />
      <p className="realm">Realm</p>
      <p className="alpha">Alpha</p>
    </div>
  );
};

export default Brand;
