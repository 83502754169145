import React, { useEffect, useState } from "react";
import "./lumpcalc.scss";
import { MDBRange } from "mdb-react-ui-kit";

const LumpCalc = () => {
  const [principal, setPrincipal] = useState(1000000);
  const [rateOfReturn, setRateOfReturn] = useState(12);
  const [durationInYears, setDurationInYears] = useState(15);
  const [profit, setProfit] = useState("");
  const [finalValue, setFinalValue] = useState("");

  //
  const calculate = () => {
    const interestRate = rateOfReturn / 100;

    let finalAmount = principal * Math.pow(1 + interestRate, durationInYears);
    finalAmount = Math.round(finalAmount * 100) / 100;
    const profitAmount = Math.round((finalAmount - principal) * 100) / 100;
    setFinalValue(finalAmount.toLocaleString());

    setProfit(profitAmount.toLocaleString());
  };
  useEffect(calculate, [principal, rateOfReturn, durationInYears]);

  //
  return (
    <div className="lumpcalc-container">
      <div className="sip-heading">Lumpsum Calculator</div>
      <div className="sipcalc-card">
        <div className="blue-background">
          <div className="container">
            <div className="sip-form">
              <div className="sip-input-item">
                <div className="sip-input-box">
                  <p className="sip-label">Total Investment</p>
                  <input
                    className="sipcalc-input"
                    value={principal}
                    onChange={(e) => setPrincipal(+e.target.value)}
                    // label="investingAmount"
                    id="investingAmount"
                    type="number"
                  />
                </div>
              </div>
              <div className="sip-input-item">
                <MDBRange
                  className="sipcalc-range"
                  defaultValue={principal}
                  value={principal}
                  onChange={(e) => setPrincipal(+e.target.value)}
                  min="50000"
                  max="1000000000"
                  step="50000"
                  id="customRange3"
                />
              </div>
              <div className="sip-input-item">
                <div className="sip-input-box">
                  <p className="sip-label">Expected return</p>
                  <input
                    className="sipcalc-input"
                    value={rateOfReturn}
                    onChange={(e) => setRateOfReturn(+e.target.value)}
                    // label="Expected return"
                    id="returnPercent"
                    type="number"
                  />
                </div>
                <MDBRange
                  defaultValue={rateOfReturn}
                  value={rateOfReturn}
                  // value={investingAmount}
                  onChange={(e) => setRateOfReturn(+e.target.value)}
                  min="0.5"
                  max="100"
                  step="0.5"
                  id="customRange3"
                />
              </div>
              <div className="sip-input-item">
                <div className="sip-input-box">
                  <p className="sip-label">Duration In Years</p>
                  <input
                    className="sipcalc-input"
                    defaultValue={durationInYears}
                    onChange={(e) => setDurationInYears(+e.target.value)}
                    value={durationInYears}
                    //   label="Number input"
                    id="yearDuration"
                    type="number"
                  />
                </div>

                <MDBRange
                  defaultValue={durationInYears}
                  // value={investingAmount}
                  value={durationInYears}
                  onChange={(e) => setDurationInYears(+e.target.value)}
                  min="0.5"
                  max="100"
                  step="0.5"
                  id="customRange3"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="sip-result container">
          <div className="result-item">
            <p className=" output-label">Estimated Return</p>
            <p className="result">{profit}</p>
          </div>
          <div className="result-item">
            <p className="output-label">Maturity Amount</p>
            <p className="result">{finalValue}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LumpCalc;
