import React, { useEffect, useState } from "react";
import "./home.scss";
import Logo from "../../data/realm alpha logo without text.png";
import IndexTicker from "../../features/index-ticker/index-ticker";
import { socket } from "../../App";
import Services from "../../features/services/services";

const Home = () => {
  const [sensexLtp, setSensexLtp] = useState({ ltp: 0, previousLtp: 0 });
  const [niftyLtp, setNiftyLtp] = useState({ ltp: 0, previousLtp: 0 });
  const [bankNiftyLtp, setBankNiftyLtp] = useState({ ltp: 0, previousLtp: 0 });
  const [niftyMidCapLtp, setNiftyMidCapLtp] = useState({
    ltp: 0,
    previousLtp: 0,
  });
  const [niftySmallCapLtp, setNiftySmallCapLtp] = useState({
    ltp: 0,
    previousLtp: 0,
  });
  // const [sensexPDC, setSensexPDC] = useState(0);
  // const [niftyPDC, setNiftyPDC] = useState(0);
  // const [bankNiftyPDC, setBankNiftyPDC] = useState(0);
  // const [niftyMidCapPDC, setNiftyMidCapPDC] = useState(0);
  // const [niftySmallCapPDC, setNiftySmallCapPDC] = useState(0);

  const indexList = {
    // Modify in IndexTick also
    nifty: {
      symbol: "NIFTY 50",
      token: '"99926000"',
    },
    banknifty: {
      symbol: "BANKNIFTY",
      token: '"99926009"',
    },
    sensex: {
      symbol: "SENSEX",
      token: '"99919000"',
    },
    niftyMidcap: {
      symbol: "NIFTY MIDCAP 100",
      token: '"99926011"',
    },
    niftySmallcap: {
      symbol: "NIFTY SMLCAP 100",
      token: '"99926032"',
    },

    // mcxgold: {
    //   symbol: "MCXGOLDEX",
    //   token: '"99920003"',
    // },
  };

  socket.on("index-tick", (data: any[]) => {
    data.forEach((tick) => {
      //   tick.symbol === indexList.nifty.symbol
      //     ? // ? setNiftyLtp(tick.ltp / 100)
      //       setNiftyLtp({ previousLtp: niftyLtp.ltp, ltp: tick.ltp / 100 })
      //     : tick.symbol === indexList.banknifty.symbol
      //     ? setBankNiftyLtp({
      //         previousLtp: bankNiftyLtp.ltp,
      //         ltp: tick.ltp / 100,
      //       })
      //     : console.log("");

      switch (tick.symbol) {
        case indexList.sensex.symbol: {
          setSensexLtp({ previousLtp: sensexLtp.ltp, ltp: tick.ltp / 100 });
          break;
        }
        case indexList.nifty.symbol: {
          setNiftyLtp({ previousLtp: niftyLtp.ltp, ltp: tick.ltp / 100 });
          break;
        }
        case indexList.banknifty.symbol: {
          setBankNiftyLtp({
            previousLtp: bankNiftyLtp.ltp,
            ltp: tick.ltp / 100,
          });
          break;
        }
        case indexList.niftyMidcap.symbol: {
          setNiftyMidCapLtp({
            previousLtp: niftyMidCapLtp.ltp,
            ltp: tick.ltp / 100,
          });
          break;
        }
        case indexList.niftySmallcap.symbol: {
          setNiftySmallCapLtp({
            previousLtp: niftySmallCapLtp.ltp,
            ltp: tick.ltp / 100,
          });
          break;
        }
        default: {
          break;
        }
      }
    });
  });

  // socket.on("previous-day-index-candle", (data: any) => {
  //   console.log(data);
  //   try {
  //     setSensexPDC(data.sensex[4]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   try {
  //     setNiftyPDC(data.nifty[4]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   try {
  //     setBankNiftyPDC(data.bankNifty[4]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   try {
  //     setNiftyMidCapPDC(data.midcapNifty[4]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   try {
  //     setNiftySmallCapPDC(data.smallcapNifty[4]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // });

  useEffect(() => {
    // socket.emit("previous-day-index-close");
    // console.log("emitting");
    // socket.emit("previous-day-index-close");
  }, []);

  return (
    <div className="home-container ">
      <div className="tagline container">
        <img src={Logo} alt="" className="tag-logo" />
        <div className="tagline-wrapper ">
          <p className="tag1">Unlock Abundance,</p>
          <p className="tag2">Embrace Wealth</p>
        </div>
        {/* <div className="market-ticker">
          <IndexTicker
            ltp={sensexLtp.ltp}
            previousLtp={sensexLtp.previousLtp}
            symbol="SENSEX"
          />
          <IndexTicker
            ltp={niftyLtp.ltp}
            previousLtp={niftyLtp.previousLtp}
            symbol="NIFTY 50"
          />
          <IndexTicker
            ltp={bankNiftyLtp.ltp}
            previousLtp={bankNiftyLtp.previousLtp}
            symbol="NIFTY BANK"
          />
          <IndexTicker
            ltp={niftyMidCapLtp.ltp}
            previousLtp={niftyMidCapLtp.previousLtp}
            symbol="NSE MIDCAP 100"
          />
          <IndexTicker
            ltp={niftySmallCapLtp.ltp}
            previousLtp={niftySmallCapLtp.previousLtp}
            symbol="NSE SMALLCAP 100"
          />
        </div> */}
      </div>
      <div className="fundexpert">
        <a href="https://arunjacobthomas.fundexpert.net">
          <button className="fundexpert-btn" type="button">
            Navigate to our Investment Platform
          </button>
        </a>
      </div>
      <div className="services ">
        <Services />
        {/* <div className="container services-container">
          <div className="service-wrap">
            <div className="service-head">INVESTMENTS SERVICES</div>
            <div className="service-list">
              <p className="service-item">Mutual Funds</p>
              <p className="service-item">Algo trading</p>
              <p className="service-item">Stock Broking</p>
              <p className="service-item">PMS</p>
            </div>
          </div>
          <div className="service-wrap">
            <div className="service-head">Insurance ADVISORY</div>
            <p className="service-item">Life Insurance</p>
            <p className="service-item">Health Insurance</p>
            <p className="service-item">General Insurance</p>
            <p className="service-item">VEHICLE INSURANCE</p>
          </div>
          <div className="service-wrap">
            <div className="service-head">Tax Consulting</div>
            <p className="service-item">Tax filing</p>
            <p className="service-item">Business Registration</p>
            <p className="service-item">Home Loans</p>
            <p className="service-item">Overseas Education Loans</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Home;
