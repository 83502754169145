import React, { useEffect, useState } from "react";
import "./sipcalc.scss";
import { MDBBtn, MDBInput, MDBRange } from "mdb-react-ui-kit";

const SipCalc = () => {
  const [investingAmount, setInvestingAmount] = useState(20000);
  const [rateOfReturn, setRateOfReturn] = useState(12);
  const [durationInYears, setDurationInYears] = useState(15);
  const [principal, setPrincipal] = useState(Number);
  const [futureValue, setFutureValue] = useState("");
  const [expectedReturn, setExpectedReturn] = useState("");

  const calculateSip = () => {
    const splittedRateOfReturn = rateOfReturn / 12 / 100;
    const totalSIPCount = durationInYears * 12;
    const principalAmount = totalSIPCount * investingAmount;
    let futureAmount =
      investingAmount *
      ((Math.pow(1 + splittedRateOfReturn, totalSIPCount) - 1) /
        splittedRateOfReturn) *
      (1 + splittedRateOfReturn);

    let expectedReturnVal = futureAmount - principalAmount;
    expectedReturnVal = Math.round(expectedReturnVal * 100) / 100;
    futureAmount = Math.round(futureAmount * 100) / 100;
    setPrincipal(principalAmount);
    setFutureValue(futureAmount.toLocaleString());
    setExpectedReturn(expectedReturnVal.toLocaleString());
  };

  useEffect(calculateSip, [investingAmount, rateOfReturn, durationInYears]);

  return (
    <div className="sipcalc-container ">
      <div className="sip-heading">SIP Calculator</div>

      <div className="sipcalc-card">
        <div className="blue-background">
          <div className="container">
            <div className="sip-form">
              <div className="sip-input-item">
                <div className="sip-input-box">
                  <p className="sip-label">Monthly Investment</p>
                  <input
                    className="sipcalc-input"
                    value={investingAmount}
                    onChange={(e) => setInvestingAmount(+e.target.value)}
                    // label="investingAmount"
                    id="investingAmount"
                    type="number"
                  />
                  {/* <MDBInput
                  className="sipcalc-input"
                  value={investingAmount}
                  onChange={(e) => setInvestingAmount(+e.target.value)}
                  // label="investingAmount"
                  id="investingAmount"
                  type="number"
                /> */}
                </div>
              </div>
              <div className="sip-input-item">
                <MDBRange
                  className="sipcalc-range"
                  defaultValue={investingAmount}
                  value={investingAmount}
                  onChange={(e) => setInvestingAmount(+e.target.value)}
                  min="5000"
                  max="1000000"
                  step="5000"
                  id="customRange3"
                />
              </div>
              <div className="sip-input-item">
                <div className="sip-input-box">
                  <p className="sip-label">Expected return</p>
                  <input
                    className="sipcalc-input"
                    value={rateOfReturn}
                    onChange={(e) => setRateOfReturn(+e.target.value)}
                    // label="Expected return"
                    id="returnPercent"
                    type="number"
                  />
                </div>
                <MDBRange
                  defaultValue={rateOfReturn}
                  value={rateOfReturn}
                  // value={investingAmount}
                  onChange={(e) => setRateOfReturn(+e.target.value)}
                  min="0.5"
                  max="100"
                  step="0.5"
                  id="customRange3"
                />
              </div>
              <div className="sip-input-item">
                <div className="sip-input-box">
                  <p className="sip-label">No. of years</p>
                  <input
                    className="sipcalc-input"
                    defaultValue={durationInYears}
                    onChange={(e) => setDurationInYears(+e.target.value)}
                    value={durationInYears}
                    //   label="Number input"
                    id="yearDuration"
                    type="number"
                  />
                </div>

                <MDBRange
                  defaultValue={durationInYears}
                  // value={investingAmount}
                  value={durationInYears}
                  onChange={(e) => setDurationInYears(+e.target.value)}
                  min="0.5"
                  max="100"
                  step="0.5"
                  id="customRange3"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="sip-result container">
          <div className="result-item">
            <p className=" output-label">Invested Amount</p>
            <p className="result">{principal}</p>
          </div>
          <div className="result-item">
            <p className="output-label">Estimated Return</p>
            <p className="result">{expectedReturn}</p>
          </div>
          <div className="result-item">
            <p className="output-label">Maturity Amount</p>
            <p className="result">{futureValue}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SipCalc;
