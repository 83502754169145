import { MDBRange } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import "./fdcalc.scss";

const FdCalc = () => {
  const [principal, setPrincipal] = useState(100000);
  const [rateOfInterest, setRateOfInterest] = useState(6.5);
  const [tenure, setTenure] = useState(5);
  const [totalInterest, setTotalInterest] = useState("");
  const [maturityValue, setMaturityValue] = useState("");

  const calculate = () => {
    const interestRateDecimal = rateOfInterest / 100;
    const maturityAmount =
      principal * Math.pow(1 + interestRateDecimal / 4, 4 * tenure);

    setMaturityValue(Math.round(maturityAmount).toLocaleString());
    setTotalInterest((Math.round(maturityAmount) - principal).toLocaleString());
    // console.log(tenure);
    // console.log(principal);
    // console.log(rateOfInterest);
  };

  useEffect(calculate, [principal, rateOfInterest, tenure]);

  return (
    <div className="fdcalc-container">
      <div className="sip-heading">FD Calculator</div>

      <div className="sipcalc-card">
        <div className="blue-background">
          <div className="container">
            <div className="sip-form">
              <div className="sip-input-item">
                <div className="sip-input-box">
                  <p className="sip-label">Total Investment</p>
                  <input
                    className="sipcalc-input"
                    value={principal}
                    onChange={(e) => setPrincipal(+e.target.value)}
                    // label="investingAmount"
                    id="investingAmount"
                    type="number"
                  />
                </div>
              </div>
              <div className="sip-input-item">
                <MDBRange
                  className="sipcalc-range"
                  defaultValue={principal}
                  value={principal}
                  onChange={(e) => setPrincipal(+e.target.value)}
                  min="50000"
                  max="100000000"
                  step="50000"
                  id="customRange3"
                />
              </div>
              <div className="sip-input-item">
                <div className="sip-input-box">
                  <p className="sip-label">Rate of interest</p>
                  <input
                    className="sipcalc-input"
                    value={rateOfInterest}
                    onChange={(e) => setRateOfInterest(+e.target.value)}
                    // label="Expected return"
                    id="returnPercent"
                    type="number"
                  />
                </div>
                <MDBRange
                  defaultValue={rateOfInterest}
                  value={rateOfInterest}
                  // value={investingAmount}
                  onChange={(e) => setRateOfInterest(+e.target.value)}
                  min="0.5"
                  max="100"
                  step="0.5"
                  id="customRange3"
                />
              </div>
              <div className="sip-input-item">
                <div className="sip-input-box">
                  <p className="sip-label">Tenure in Years</p>
                  <input
                    className="sipcalc-input"
                    defaultValue={tenure}
                    onChange={(e) => setTenure(+e.target.value)}
                    value={tenure}
                    //   label="Number input"
                    id="yearDuration"
                    type="number"
                  />
                </div>

                <MDBRange
                  defaultValue={tenure}
                  // value={investingAmount}
                  value={tenure}
                  onChange={(e) => setTenure(+e.target.value)}
                  min="0.5"
                  max="100"
                  step="0.5"
                  id="customRange3"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="sip-result container">
          <div className="result-item">
            <p className=" output-label">Interest earned</p>
            <p className="result">{totalInterest}</p>
          </div>
          <div className="result-item">
            <p className="output-label">Maturity Amount</p>
            <p className="result">{maturityValue}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FdCalc;
