import React from "react";
import "./services.scss";

const Services = () => {
  return (
    <div className="serv-container container">
      <div className="serv-wrapper">
        <div className="serv">
          <h1 className="serv-head">INVESTMENT SERVICES</h1>
          <div className="serv-list">
            <p className="serv-items">Mutual Funds</p>
            <p className="serv-items">Algo trading</p>
            <p className="serv-items">Stock Broking</p>
            <p className="serv-items">PMS</p>
          </div>
          <i className="itag"></i>
        </div>
      </div>
      <div className="serv-wrapper">
        <div className="serv">
          <h1 className="serv-head">Insurance ADVISORY</h1>
          <div className="serv-list">
            <p className="serv-items">Life Insurance</p>
            <p className="serv-items">Health Insurance</p>
            <p className="serv-items">General Insurance</p>
            <p className="serv-items">Vehicle Insurance</p>
          </div>
          <i className="itag"></i>
        </div>
      </div>
      <div className="serv-wrapper">
        <div className="serv">
          <h1 className="serv-head">Tax Consulting</h1>
          <div className="serv-list">
            <p className="serv-items">Tax filing</p>
            <p className="serv-items">Business Registration</p>
            <p className="serv-items">Home Loans</p>
            <p className="serv-items">Overseas Education Loans</p>
          </div>
          <i className="itag"></i>
        </div>
      </div>
    </div>
  );
};

export default Services;
