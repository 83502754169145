import React from "react";
import "./disclaimer.scss";

const Disclaimer = () => {
  return (
    <div className="disclaimer-container container">
      <div className="disclaimer-heading">Disclaimer</div>
      <p className="disclaimer-text">
        Mutual Fund investments are subject to market risks, read all scheme
        related documents carefully. The NAVs of the schemes may go up or down
        depending upon the factors and forces affecting the securities market
        including the fluctuations in the interest rates. The past performance
        of the mutual funds is not necessarily indicative of future performance
        of the schemes. The Mutual Fund is not guaranteeing or assuring any
        dividend under any of the schemes and the same is subject to the
        availability and adequacy of distributable surplus. Investors are
        requested to review the prospectus carefully and obtain expert
        professional advice with regard to specific legal, tax and financial
        implications of the investment/participation in the scheme.
      </p>
    </div>
  );
};

export default Disclaimer;
